<template>
  <div class="row">
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <div class="alert-text mt-2 text-center">
          <h1 class="black--text"><b>My Favourites</b></h1>
        </div>
      </b-alert>
    </v-col>
    <div class="col-lg-4 col-sm-4">
      <div class="bg-light-success text-center pt-3 pb-3">
        <p class="font-weight-bold font-size-h3 mb-3">
          TCC Academy
        </p>
        <hr>
        <p class="font-weight-bold font-size-h6 m-0">
          Coming Soon...
        </p>
      </div>
    </div>
    <div class="col-lg-4 col-sm-4">
      <div class="bg-light-success text-center pt-3 pb-3">
        <p class="font-weight-bold font-size-h3 mb-3">
          Events
        </p>
        <hr>
        <p class="font-weight-bold font-size-h6 m-0">
          Coming Soon...
        </p>
      </div>
    </div>
    <div class="col-lg-4 col-sm-4">
      <div class="bg-light-success text-center pt-3 pb-3">
        <p class="font-weight-bold font-size-h3 mb-3">
          Feature/News
        </p>
        <hr>
        <p class="font-weight-bold font-size-h6 m-0">
          <a target="_blank" href="https://tccadr.org/news">Go to News <i class="fas fa-external-link-alt blue--text"></i></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "favourites",
  components: {},
  mounted() {
  },
  methods: {}
};
</script>
